import React from 'react';
import Header from './Header';
import Footer from './Footer';
import useFetch from "../ApiClient/GetApi";
import { IMAGE_URL } from '../Utils/urls';
import { Link } from 'react-router-dom';
import NewsSection from './NewsSection';
import KeyData from './KeyData';
import Graph from './Graph';
import AddSection from './AddSection';
import Rating from './Rating';

export default function Home() {
    return (
        <div>
            <div className="wrappero">
                <Header />
                <div className="city_main_banner">
                    <div className="main-banner-slider">
                        <div>
                            <figure className="overlay">
                                <img src="extra-images/main-banner.jpg" alt="" />
                                <div className="banner_text" style={{ height: "270px", width: "600px", color: "white" }}>
                                    <h5 style={{color:"white"}}>The cloud-based Results collation and Analytics tool</h5>
                                    <h5 style={{color:"white",fontWeight:"bold",fontStyle:"italic"}}>Inclusive, Accurate, Secure, Detailed</h5>
                                    <h2 style={{color:"white",fontWeight:"500"}}>Empowering Africa with Data-Driven Insights</h2>
                                    <p style={{color:"white"}}>Elections, Economy, Trade, Surveys, Employment, Reports</p>
                                    
                                </div>
                            </figure>
                        </div>
                    </div>
                </div>
              
                <KeyData/>
                <Graph/>
            <NewsSection />
            <AddSection/>
            {/* <Rating /> */}
            <Footer />

            </div>


        </div>
    );
}

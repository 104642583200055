import React, { useState } from 'react';
import Header from './Header';
import { red } from '../Utils/colors';
import { PostApi } from '../ApiClient/PostApi';
import { success_toaster, error_toaster } from "../Utils/Toaster";
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import { SITE_KEY } from '../Utils/urls';

export default function ContactUs() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [captchaToken, setCaptchaToken] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleCaptcha = (value) => {
        setCaptchaToken(value);
    };

    const submitContactUs = async () => {
        if (!captchaToken) {
            error_toaster("Please complete the reCAPTCHA.");
            return;
        }

        try {
            let response = await PostApi("submitContactUs", { ...formData, captcha: captchaToken });
            console.log(response);
            if (response.data.status === "1") {
                success_toaster(response.data.message);
                navigate("/");
            } else {
                error_toaster(response.data.message);
            }
        } catch (error) {
            console.error('Error submitting form', error);
            alert('An error occurred while sending your message');
        }
    };

    return (
        <div>
            <Header />
            <div className='container'>
                <div className="city_event_detail contact" style={{ border: "1px solid red", margin: "10px", padding: "10px", background: red }}>
                    <div className="section_heading center">
                        <h2 style={{ color: "white" }}>Contact With Us</h2>
                    </div>
                    <div className="event_booking_form">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="event_booking_field">
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="event_booking_field">
                                    <input
                                        type="text"
                                        placeholder="Email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="event_booking_field">
                                    <input
                                        type="text"
                                        placeholder="Subject"
                                        name="subject"
                                        value={formData.subject}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="event_booking_area">
                                    <textarea
                                        placeholder="Enter Your Message Here"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleChange}
                                    />
                                </div>
                                <ReCAPTCHA
                                    sitekey={SITE_KEY} // Replace with your reCAPTCHA site key
                                    onChange={handleCaptcha}
                                />
                                <button className="theam_btn btn2" onClick={submitContactUs}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
